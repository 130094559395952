.container {
    flex: 1 1 auto;
    position: relative;
    perspective: 4000px;
    overflow: hidden;
}

#wheel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
}
