.WheelItem {
    margin: 0;
    display: block;
    position: absolute;
    opacity: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s background-position ease-in-out;
}

.WheelItem > span {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.WheelItem > span > * {
    flex: 0 0 80%;
}
