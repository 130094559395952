.Background {
    width: 100%;
    height: 100%;

    background-color: #7d2c00;
    background-image: url("../Images/brick-wall.png");

    display: flex;
}

@media (max-width: 1000px) {
    #HorizontalLeft {
        flex: 0 0 10px;
    }
    #HorizontalCenter {
        flex: 1 1 auto;
    }
    #HorizontalRight {
        flex: 0 0 10px;
    }
}

@media (min-width: 1000px) {
    #HorizontalLeft {
        flex: 1 1 auto;
    }
    #HorizontalCenter {
        flex: 1 1 auto;
    }
    #HorizontalRight {
        flex: 1 1 auto;
    }
}

#App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#App.blur {
    filter: blur(3px);
}

#VerticalTop {
    flex: 0 0 5%;
    width: 100%;
    max-height: 5%;
}

#VerticalBottom {
    flex: 0 0 10vh;
    width: 100%;
}
