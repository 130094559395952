#Restart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#Restart.hide {
    display: none;
}

#Restart.show {
    display: flex;
}

#Restart .thing {
    flex: 0 0 auto;
    position: absolute;
    z-index: 10000;
    width: 50%;
}

#Restart path {
    fill: dodgerblue;
    stroke-width: 5px;
    stroke: darkblue;
}
