.slot {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;

    background-color: transparent;
}

.wheel-top {
    flex: 1 1 auto;
}

.wheel-wrapper {
    flex: 8 8 auto;
    display: flex;
    flex-direction: column;
}

.slot-separator {
    flex: 0.5 0.5 auto;
}

.button-wrapper {
    flex: 0 0 15vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}
