#Footer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FooterItem {
    flex: 0 0 8vh;
    height: 8vh;
    border-radius: 3px;
    margin: 0px 10px;
}

#Bag {
    fill: transparent;
    stroke-width: 8px;
    stroke: white;
    cursor: pointer;
}

#SoundIcon {
    fill: transparent;
    stroke-width: 20px;
    stroke: white;
    cursor: pointer;
}

#SoundIcon #slash {
    fill: red;
    stroke-width: 4px;
    stroke: black;
}

