.clubDialog {
    /* background-image: url("../../Images/argyle.png"); */
    background-color: #f0f0f1;
}

#closeDialog {
    float: right;
    width: 50px;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

#clubListWrapper {
    float: left;
    width: 100%;
    height: 100%;
}

.clubButton {
    min-width: 15vh;
    margin-top: 10px;
    height: 15vh;
    border-radius: 5px;
    font-weight: bold;
    background-color: #292c44;
    border: 5px solid white;
    color: white;
    margin-right: 15px;
}

.clubButton.selected {
    border-color: #4f80e1;
}

.clubButton:not(.selected) {
    border-color: #ff5349;
}

.dialogSection {
    margin-bottom: 25px;
}
