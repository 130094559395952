.button {
    width: 15vh;
    height: 15vh;
    color: #fff;
    font-weight: bold;
    border: 5px solid #060606;
    border-radius: 50%;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#fff176),
        to(#fbe33d)
    );
    background: -webkit-linear-gradient(top, #fff176 0%, #fbe33d 100%);
    background: -o-linear-gradient(top, #fff176 0%, #fbe33d 100%);
    background: linear-gradient(to right, #f9e962 0%, #fbe33d 100%);
    box-shadow: 3px 1.5vh 0px #e6dd5a, 10px 3px 20px rgba(107, 107, 107, 0.42);
}

.button.left {
    box-shadow: 5px -1.5vh 0px #e6dd5a,
        -5px -3px 20px rgba(107, 107, 107, 0.42);
}

.button.center {
    box-shadow: 0px -1.5vh 0px #e6dd5a, -5px -3px 20px rgba(107, 107, 107, 0.42);
}

.button.right {
    box-shadow: -5px -1.5vh 0px #e6dd5a, 5px 3px 20px rgba(107, 107, 107, 0.42);
}

.button.pushed.left {
    box-shadow: 3px -5px 0px #e6dd5a, -5px -3px 20px rgba(107, 107, 107, 0.42);
    transition: box-shadow 0.5s;
}

.button.pushed.center {
    box-shadow: 3px -5px 0px #e6dd5a, -3px -3px 20px rgba(107, 107, 107, 0.42);
    transition: box-shadow 0.5s;
}

.button.pushed.right {
    box-shadow: -3px -5px 0px #e6dd5a, 5px 3px 20px rgba(107, 107, 107, 0.42);
    transition: box-shadow 0.5s;
}

.button:hover:not(.disabled) {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ffe603),
        to(#fae979)
    );
    background: -webkit-linear-gradient(top, #ffe603 0%, #fae979 100%);
    background: -o-linear-gradient(top, #ffe603 0%, #fae979 100%);
    background: linear-gradient(to left, #fff12e 0%, #fff176 100%);
    cursor: pointer;
    border: 5px solid #968d0a;
}

.button:hover:not(.disabled) svg {
    fill: #968d0a;
}
