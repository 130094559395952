#Machine {
    flex: 1 1 auto;
    width: 90%;

    display: flex;
    flex-direction: row;

    border: 10px solid transparent;
    border-radius: 30px;
    /* overflow: hidden; */

    background-color: #F6EDD3;
}

.slot:nth-child(1) .wheel-wrapper {
    border-right: 5px solid black;
    border-left: 10px solid black;
}

.slot:nth-child(2) .wheel-wrapper {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.slot:nth-child(3) .wheel-wrapper {
    border-left: 3px solid black;
    border-right: 10px solid black;
}

.slot .wheel-wrapper {
    border-top: 10px solid black;
    border-bottom: 10px solid black;
}
