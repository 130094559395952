#WashInWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: black;
    animation: fadeOut 3s;
    -webkit-animation: fadeOut 3s;
    opacity: 0;
    top: -10000px;
    left: -10000px;
}

#WashInSubWrapper {
    background: black;
    animation: expandFromCenter 3s;
    -webkit-animation: expandFromCenter 3s;
    width: 100%;
    height: 100%;
}

@-webkit-keyframes expandFromCenter {
    0% {
        transform: scale(0.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(50);
    }
}

@keyframes expandFromCenter {
    0% {
        transform: scale(0.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(50);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
    }
    50% {
        opacity: 0.5;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
    }
    100% {
        opacity: 0;
        z-index: -1;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
    }
}

#toad {
    fill: white;
}
