#TitleWrapper {
    position: relative;
    z-index: 999;
    top: 10px;

    animation: MoveUpDown 2s linear infinite;
    -webkit-animation: MoveUpDown 2s linear infinite;
}

@-webkit-keyframes MoveUpDown {
    0% {
        top: 10px;
    }
    50% {
        top: 30px;
    }
    100% {
        top: 10px;
    }
}

@keyframes MoveUpDown {
    0% {
        top: 10px;
    }
    50% {
        top: 30px;
    }
    100% {
        top: 10px;
    }
}

.background {
    fill: #a5682a;
    stroke-width: 4px;
    stroke: white;
}

.foreground * {
    stroke-width: 4px;
    stroke: black;
}

.foreground .Red {
    fill: red;
}

.foreground .Yellow {
    fill: yellow;
}

.foreground .Green {
    fill: greenyellow;
}

.foreground .Blue {
    fill: darkturquoise;
}