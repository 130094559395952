.letters {
    stroke-width: 5px;
    stroke: black;
}

.wood .letters {
    fill: red;
}

.hybrid .letters {
    fill: yellow;
}

.iron .letters {
    fill: greenyellow;
}

.putter .letters,
.wedge .letters {
    fill: darkturquoise;
}

.Star #background,
.Star #eyes {
    fill: black;
}

.Star #foreground,
.Star #pupils {
    fill: yellow;
}

.Beer #background {
    fill: #c55e00;
}

.Plank .section1 {
    fill:#f5e95a;
    stroke-width:1.33333325
}

.Plank .section2 {
    fill:#b0a864;
    stroke-width:1.33333325;
}

.Plank .section3 {
    fill:#3399ae;
    stroke-width:1.33333325;
}

.Plank .section4 {
    fill:#dd3d18;
    stroke-width:1.33333325;
}

.Plank .section5 {
    fill:#636259;
    stroke-width:1.33333325;
}

.Plank .section6 {
    fill:#635c37;
    stroke-width:1.33333325;
}

.Plank .secion7 {
    fill:#080700;
    fill-opacity:1;
    stroke-width:1.33333325;
}


